import {
	CreditCardJsInitResponse,
	CardInfo,
	GetTokenOnSuccess,
	GetTokenOnFailure,
	GetTokenSuccessResponse,
	GetTokenFailureResponse,
	PaymentSystemReturnStatus,
	YamatoCreditCardDetail,
	GetTokenOptions
} from "../../../types/v1/credit_card";
import {
	WebcollectTokenLib,
	CreditCardYamatoInitData,
	WebcollectTokenLibCreateTokenFailureResponse,
	WebcollectTokenLibCreateTokenSuccessResponse,
	YamatoGetTokenInfo,
	AuthDiv
} from "../../../types/v1/credit_card/yamato";
import { ScriptLoadedType, loadExternalScript } from "../../common/loadExternalScript";
import { yamatoEndpoints } from "../../endpoint/yamato";
import { getTokenError, TokenErrorCode } from "../error/tokenError";
import { maskCardNumber, maskSecurityCode } from "../util";
import axios from "axios";
import { getAuthHeaders } from "../../common/getAuthHeaders";
import { delay, urls } from "..";
import { CreditAgency } from "../../../types/v1/credit_card/enum";
import { generateHash } from "../../common/generateHash";
import { nanoid } from "nanoid";
import { formatExpire } from "../../common/formatExpire";

declare global {
	interface Window {
		/** ヤマト提供jsのライブラリ名 */
		WebcollectTokenLib: WebcollectTokenLib;
	}
}

interface PaymentCardTemporary {
	/** ヤマトの場合 */
	[CreditAgency.YAMATO_WEB_COLLECT]?: {
		token: string;
		member_id: string;
		auth_key: string;
	};
}

/**
 * カード情報を設定を元にtokenを取得する
 *
 * `window.WebcollectTokenLib.createToken`関数はコールバック関数でしか結果を取得できないため、Promiseを生成し、コールバック内で`resolve`か`reject`しています。
 * 必ず、そのような実装をキープしてください
 * @param cardInfo カード情報
 * @param settings 決済システム設定情報
 * @param cardDetail 決済システムサーバーから取得した登録済みカード詳細情報, cardInfo.card_idが存在する場合のみ
 * @param onSuccess token発行成功時のコールバック
 * @param onFailure token発行失敗時のコールバック
 */
type CreditYamatoGetTokenFunction = (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>,
	options?: GetTokenOptions,
	cardDetail?: YamatoCreditCardDetail,
	onSuccess?: GetTokenOnSuccess,
	onFailure?: GetTokenOnFailure
) => Promise<GetTokenSuccessResponse | GetTokenFailureResponse>;

/**
 * 設定情報（向き先）を元にjsファイルを取得しdocumentに埋め込む
 * @param settings 決済システム設定情報
 */
export const creditYamatoInit = async (
	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>
) => {
	const loadType = await loadExternalScript(
		yamatoEndpoints.tokenGeneration[settings.environment],
		"body",
		"webcollect-embedded-token"
	);

	if (loadType === ScriptLoadedType.FIRST_TIME) {
		await delay(5000);
	} else {
		await delay(1000);
	}
};

/**
 * トークン取得リクエストの共通部分を引数から作成する
 * @param cardInfo カード情報
 * @param settings 決済システム設定
 * @param checkSum 認証用チェックサム（新規 or 新規かつ登録 or 登録済みで形式が変わる）
 * @returns トークン取得リクエストの共通部分オブジェクト
 */
const createGetTokenInfoCommon = (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>,
	checkSum: string
) => {
	const format = formatExpire({
		year: { number: cardInfo.expires.year, length: 2 },
		month: { number: cardInfo.expires.month, padding: true }
	});

	return {
		traderCode: settings.trader_code,
		authDiv: settings.auth_dev,
		checkSum: checkSum,
		cardNo: cardInfo.number,
		cardOwner: cardInfo.name,
		cardExp: `${format.month}${format.year}`,
		...(settings.is_use_card_verification_value && { securityCode: cardInfo.security_code })
	};
};

/**
 * トークン取得成功時のコールバック処理の共通部分をまとめたハンドラー
 * @param cardInfo カード情報
 * @param token 取得したトークン
 * @param onFailure `getToken`関数の引数で受け取った成功時コールバック
 * @returns トークン取得成功時オブジェクト
 */
const handleSuccessCallback = (
	cardInfo: CardInfo,
	token: string,
	onSuccess?: GetTokenOnSuccess
) => {
	const successResponse: GetTokenSuccessResponse = {
		status: PaymentSystemReturnStatus.SUCCESS,
		token: token,
		masked_security_code: maskSecurityCode(cardInfo.security_code),
		number: maskCardNumber(cardInfo.number),
		name: cardInfo.name,
		expires: { ...cardInfo.expires },
		brand: cardInfo.brand
	};

	window.LeghornPayment.card = successResponse;
	if (onSuccess) onSuccess(successResponse);

	console.log(successResponse);
	return successResponse;
};

/**
 * トークン取得失敗時のコールバック処理の共通部分をまとめたハンドラー
 * @param res ヤマトからのトークン取得結果
 * @param settings 決済システム設定
 * @param onFailure `getToken`関数の引数で受け取った失敗時コールバック
 * @returns トークン取得失敗時オブジェクト
 */
const handleFailureCallback = (
	res: WebcollectTokenLibCreateTokenFailureResponse,
	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>,
	onFailure?: GetTokenOnFailure,
	paymentSystemErrorCodes?: TokenErrorCode[]
): GetTokenFailureResponse => {
	const failureResponse: GetTokenFailureResponse = {
		status: PaymentSystemReturnStatus.FAILURE,
		errors: getTokenError(
			res.errorInfo?.map(ei => ei.errorCode) || [],
			settings,
			paymentSystemErrorCodes
		)
	};

	window.LeghornPayment.card = failureResponse;

	if (onFailure) onFailure(failureResponse);

	console.log(failureResponse);
	return failureResponse;
};

/**
 * カード情報を設定を元にtokenを取得する
 *
 * `window.WebcollectTokenLib.createToken`関数はコールバック関数でしか結果を取得できないため、Promiseを生成し、コールバック内で`resolve`か`reject`しています。
 * 必ず、そのような実装をキープしてください
 * @param cardInfo カード情報
 * @param settings 決済システム設定情報
 * @param cardDetail 決済システムサーバーから取得した登録済みカード詳細情報, cardInfo.card_idが存在する場合のみ
 * @param onSuccess token発行成功時のコールバック
 * @param onFailure token発行失敗時のコールバック
 */
export const creditYamatoGetToken: CreditYamatoGetTokenFunction = (
	cardInfo,
	settings,
	options?,
	cardDetail?,
	onSuccess?,
	onFailure?
) => {
	/** 会員ID, 詳細がない場合は新規かつ登録すると判断してランダムな値を作成 */
	const memberId = cardDetail?.member_id || nanoid(30);
	/** カード認証キー, 詳細がない場合は新規かつ登録すると判断してランダムな値を作成 */
	const authKey = cardDetail?.auth_key || nanoid(8);

	settings.auth_dev = options?.isProvisionalCard
		? AuthDiv.NO_SECURE_WITH_SECURITY_CODE
		: settings.auth_dev;

	const checkSum = generateHash(
		"lower",
		memberId,
		authKey,
		settings.access_key,
		settings.auth_dev
	);

	const { cardNo, ...getTokenInfoCommon } = createGetTokenInfoCommon(
		cardInfo,
		settings,
		checkSum
	);

	const getTokenRequest: YamatoGetTokenInfo = {
		...getTokenInfoCommon,
		optServDiv: "01",
		memberId: memberId,
		authKey: authKey,
		...(cardDetail
			? // 登録済みカードの場合、
				{ lastCreditDate: cardDetail.last_credit_date, cardKey: "1" }
			: // 新規カードかつ登録の場合
				{ cardNo: cardNo })
	};

	return new Promise((resolve, reject) => {
		try {
			const successCallback = async (res: WebcollectTokenLibCreateTokenSuccessResponse) => {
				if (!cardInfo.security_code) {
					reject(
						handleFailureCallback(
							{ returnCode: "1", errorInfo: [] },
							settings,
							onFailure,
							[TokenErrorCode.SECURITY_CODE_REQUIRED]
						)
					);
					return;
				}

				const cardTemporaryRequest: PaymentCardTemporary = {
					[CreditAgency.YAMATO_WEB_COLLECT]: {
						token: res.token,
						member_id: memberId,
						auth_key: authKey
					}
				};

				const cardTmpResponse = await axios.post(
					`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.PAYMENT_CARD_TEMPORARY}`,
					cardTemporaryRequest,
					{ headers: { ...getAuthHeaders(settings.api_key, settings.auth_value) } }
				);

				console.log(cardTmpResponse.data);

				const successResponse = handleSuccessCallback(cardInfo, res.token, onSuccess);
				resolve(successResponse);
			};

			const failureCallback = (res: WebcollectTokenLibCreateTokenFailureResponse) => {
				const failureResponse = handleFailureCallback(res, settings, onFailure);
				reject(failureResponse);
			};

			console.log(getTokenRequest);
			void window.WebcollectTokenLib.createToken(
				getTokenRequest,
				successCallback,
				failureCallback
			);
		} catch (e) {
			reject(e);
			console.error(e);
		}
	});
};
